import { render, staticRenderFns } from "./photo.vue?vue&type=template&id=1e61800d&scoped=true&"
import script from "./photo.vue?vue&type=script&lang=babel&"
export * from "./photo.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e61800d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VImg,VRow})
